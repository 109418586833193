import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"

const RequestPickupPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-3.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Request Pick-Up</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<h2>Schedule Furniture Pick up</h2>
					<p>We will will use this information to contact you to set a time for pick up.</p>
					<p><i className="bold">PLEASE NOTE - WE CANNOT ACCEPT</i>: TVs, mattresses or bedding, infant furniture, fluorescent light fixtures or bulbs, pressboard furniture, partial cans of paint, exercise equipment, entertainment centers, large office desks, household chemicals, medical equipment, unframed or broken mirrors, or anything that is damaged, dirty, outdated, or not likely to resell.</p>
					<a href="https://forms.gle/WvAAZdsn3FYrLZYS9" className="button" target="_blank">Fill out form</a>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default RequestPickupPage

export const Head = () => (
	<Seo
        title="Request Pick-Up | Habitat For Humanity Northwoods Wisconsin"
    />
)